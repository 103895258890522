<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" justify="center">
      <el-col :lg="16" :md="14" :sm="13" :xs="24">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Nombre, correo" v-model="searchForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col style="display: flex; justify-content: flex-start;" :lg="8" :md="10" :sm="11" :xs="24">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          size="mini"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleCreate"
          size="mini"
          type="success"
          icon="el-icon-bottom"
        >Exportar</el-button>
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col class="grid-content" style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="24">
          <el-select v-model="searchForm.payment_status" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Estatus">
            <el-option
              v-for="state in stateStatus"
              :key="state"
              :label="state"
              :value="state">
            </el-option>
          </el-select>
      </el-col>
      <el-col class="grid-content" align="center" :lg="16" :md="16" :sm="14" :xs="24">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xs="24" style="display: flex; justify-content: flex-end">
        <el-row type="flex" justify="end">
          <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
            :total="parseInt(totalPages)">
          </el-pagination>
        </el-row>
      </el-col>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      height="600"
      fit
      border
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column label="ID Ex alumno" fixed width="120" align="center">
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{scope.row._id}}</div>
                <span>{{ scope.row._id.toString().substr(-4) }}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ID Conekta" width="120" align="center">
        <template slot-scope="scope">
            <div v-if="scope.row.conekta_id">
                <el-tooltip class="item" effect="dark" placement="top">
                    <div slot="content">{{scope.row.conekta_id}}</div>
                    <span>{{ scope.row.conekta_id.toString().substr(-4) }}</span>
                </el-tooltip>
            </div>
        </template>
      </el-table-column>
      <el-table-column label="Pago realizado" width="120" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.payment_made == true">
            <el-tag type="success">{{ scope.row.payment_made }}</el-tag>
          </div>
          <div v-else>
            <el-tag>{{ scope.row.payment_made }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Nombre(s)" width="130" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Apellidos" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.last_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Telefono" width="130" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Correo" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sucursal preveniente" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.place }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Ultimo nivel alcanzado" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.level }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha registro" width="150" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.created_at | formatDateTime }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
    <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
      :total="parseInt(totalPages)">
    </el-pagination>
  </el-row>
  </div>
</template>

<script>
import { search, getPage } from '@/api/exStudent'

export default {
  data () {
    return {
      searchForm: {
        query: '',
        payment_status: '',
        initDate: '',
        endDate: ''
      },
      filterDateRegisterRange: '',
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      totalPages: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25,
      stateStatus: ['Pagado', 'Sin pagar']
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  methods: {
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        payment_status: '',
        initDate: '',
        endDate: ''
      }
      this.handleSearchFilter()
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    }
  }
}
</script>
